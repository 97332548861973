/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Button, Text, Image, Subtitle } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-bqlt9c --style3 --full" anim={""} name={"einleitung"} animS={"3"} border={""} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" anim={null} style={{"maxWidth":1200}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim4 --anim-s3 pb--0 pl--0 pr--0 pt--0" anim={"4"} animS={"3"} style={{"maxWidth":900}}>
              
              <Title className="title-box fs--86 title-box--invert" style={{"maxWidth":890}} content={"Plumbing Services<br>Berlin"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shape4 fs--22 mt--30" content={"Kontaktieren Sie uns"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" anim={null} name={"information"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --left el--3 flex--center" anim={""} animS={"3"} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":400}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-supplementary);\">Plumbing Problems & Repairs</span>"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":400}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-supplementary);\">Water, Gas, and Heating Installation</span>"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":400}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-supplementary);\">Periodical Servicing & Maintenance</span>"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--50 pt--50" name={"uber-mich"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --left el--2 flex--center" anim={"2"} animS={"3"} style={{"maxWidth":1200}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":"500"}}>
              
              <Title className="title-box fs--62" content={"Ivan Kräuslich"}>
              </Title>

              <Text className="text-box fs--18 w--900 ls--002" style={{"maxWidth":500}} content={"Plumbing Services &nbsp;&nbsp;&nbsp; ⭐ ⭐ ⭐ ⭐ ⭐"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":500}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/150/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/150/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/150/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/150/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/150/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/150/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--16 pt--16" name={"dienstleistungen"}>
          
          <ColumnWrap className="column__flex --left el--3 flex--center" style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper className="--shape4 js-anim  --anim2 --anim-s3 pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/Finance/finanfce-1.svg"} sizes={"100vw"} style={{"maxWidth":96}} srcSet={""} svgColor={"rgba(25,53,125,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Drain Cleaning"}>
              </Subtitle>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --anim-s3 pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/Finance/finanfce-3.svg"} sizes={"100vw"} style={{"maxWidth":96}} srcSet={""} svgColor={"rgba(25,53,125,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Water Heater Repair"}>
              </Subtitle>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --anim-s3 pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/Finance/finanfce-2.svg"} sizes={"100vw"} style={{"maxWidth":96}} srcSet={""} svgColor={"rgba(25,53,125,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Faucet Installation"}>
              </Subtitle>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --anim-s3 pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/Finance/finanfce-4.svg"} sizes={"100vw"} style={{"maxWidth":96}} srcSet={""} svgColor={"rgba(25,53,125,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Plumbing Installation"}>
              </Subtitle>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --anim-s3 pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/Finance/finanfce-2.svg"} sizes={"100vw"} style={{"maxWidth":96}} srcSet={""} svgColor={"rgba(25,53,125,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Heating Installation"}>
              </Subtitle>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --anim-s3 pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/Finance/finanfce-5.svg"} sizes={"100vw"} style={{"maxWidth":96}} srcSet={""} svgColor={"rgba(25,53,125,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Plumbing Problems & Repairs"}>
              </Subtitle>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"blank"} layout={"l30"}>
        </Column>


        <Column className="--center pb--20 pt--20" name={"referenzen"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"referenzen-2"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1200}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--62" content={"<span style=\"color: var(--color-dominant);\">Referenzen</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s3" anim={"4"} animS={"3"}>
              
              <Text className="text-box text-box--left" content={"<span style=\"color: var(--color-dominant);\">\"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten. Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten.\"</span>"}>
              </Text>

              <Text className="text-box text-box--left w--900" content={"<span style=\"color: var(--color-dominant);\">—   Jana Müller &nbsp; &nbsp; ⭐ ⭐ ⭐ ⭐ ⭐</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s3" anim={"4"} animS={"3"}>
              
              <Text className="text-box text-box--left" content={"<span style=\"color: var(--color-dominant);\">\"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten. Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten.\"</span>"}>
              </Text>

              <Text className="text-box text-box--left w--900" content={"<span style=\"color: var(--color-dominant);\">—   Jana Müller &nbsp; &nbsp; ⭐ ⭐ ⭐ ⭐ ⭐</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s3" anim={"4"} animS={"3"}>
              
              <Text className="text-box text-box--left" content={"<span style=\"color: var(--color-dominant);\">\"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten. Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten.\"</span>"}>
              </Text>

              <Text className="text-box text-box--left w--900" content={"<span style=\"color: var(--color-dominant);\">—   Jana Müller &nbsp; &nbsp; ⭐ ⭐ ⭐ ⭐ ⭐</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-17zetwm pb--80 pt--80" name={"kontakt"} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/150/fullscreen-2_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: white;\">Need Reliable Plumbing Services?</span>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shape4" content={"Kontaktieren Sie uns"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--20 pt--20" name={"partei"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left fs--20" content={"Ivan Kräuslich"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--right fs--20" content={"2021 – Erstellt mit <a href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}